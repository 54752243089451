import React from 'react';
import Landing from 'components/landing';

const App = () => {
	return (
		<Landing/>
	)
}

export default App;
