import React, { useEffect, useState } from 'react';

import LandingLogo from 'assets/images/landing-logo.svg'

import Secure from 'assets/images/secure.svg'
import Reliable from 'assets/images/reliable.svg'
import Scalable from 'assets/images/scalable.svg'

import Admin2 from 'assets/images/admin2.png'
import Admin3 from 'assets/images/admin3.png'
import Admin4 from 'assets/images/admin4.png'

import Project from 'assets/images/project.svg'
import MultiLingual from 'assets/images/multilingual.svg'
import Currency from 'assets/images/currency.svg'
import BetShops from 'assets/images/betshops.svg'
import Configurable from 'assets/images/configurable.svg'

import Borders from 'assets/images/grid.svg'
import BorderSquare from 'assets/images/gridSquare.svg'

import Digitain from 'assets/images/digitain.png'

import Demo from 'assets/images/Demo.mp4'

import VideoPlay from 'assets/images/videoPlay.svg'


var Emblem = {
    init: function (el, wrapper, str) {
        var element = document.querySelector(el);
        var wrapperElement = document.querySelector(wrapper);
        var text = str ? str : element.innerHTML;
        element.innerHTML = '';
        for (var i = 0; i < text.length; i++) {
            var letter = text[i];
            var span = document.createElement('span');
            var node = document.createTextNode(letter);
            var r = (360 / text.length) * (i);
            var x = (Math.PI / text.length).toFixed(0) * (i);
            var y = (Math.PI / text.length).toFixed(0) * (i);
            span.appendChild(node);
            span.style.webkitTransform = 'rotateZ(' + r + 'deg) translate3d(' + x + 'px,' + y + 'px,0)';
            span.style.transform = 'rotateZ(' + r + 'deg) translate3d(' + x + 'px,' + y + 'px,0)';
            wrapperElement.appendChild(span);
        }
    }
};

const checkVisible = elem => {
    const rect = elem.getBoundingClientRect();
    const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
}


const Landing = () => {

    //Scroll Top

    const [showButton, setShowButton] = useState(false);

    const [videoPaused, setVideoPaused] = useState(true);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.pageYOffset > 50) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        });
    }, []);

    //Play Video

    useEffect(() => {
        const video = document.getElementsByClassName('rt--video')[0];
        video.addEventListener("pause", () => {
            setVideoPaused(true)
        })

        video.addEventListener("play", () => {
            setVideoPaused(false)
        })
    }, [])

    const playVideo = () => {
        const video = document.getElementsByClassName('rt--video')[0]
        video.play()
    }

    // This function will scroll the window to the top 
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        Emblem.init('.emblem', '.emblem-rotate');
    }, [])

    const toggleClassName = () => {
        const contentDiv = document.getElementById("content");
        if (checkVisible(contentDiv)) {
            contentDiv.classList.add("scrolled")
        }
        else {
            contentDiv.classList.remove("scrolled")
        }
    }

    useEffect(() => {
        toggleClassName();
        window.addEventListener('scroll', () => {
            toggleClassName()
        });

        return () => {
            window.removeEventListener('scroll', toggleClassName);
        }
    }, [])


    useEffect(() => {
        Emblem.init('.emblem', '.emblem-rotate');
    }, [])

    return (
        <div className='rt--landing'>
            {showButton && (
                <button onClick={scrollToTop} className="back-to-top">
                    <i className='ic_arrow-up'/>
                </button>
            )}
            <div className='landing'>
                <div className='landing-borders'>
                    {/* HEADER Section */}
                    <div className='landing-header'>
                        <div>
                            <img src={LandingLogo}></img>
                        </div>
                        <div>
                            <a href='#demoSection'><span>Demo</span></a>
                            <a href='#featuresSection'><span>Key Features</span></a>
                            <a href='#advantagesSection'><span>Advantages</span></a>
                        </div>
                    </div>
                    {/* HEADER Section */}

                    {/* HERO Section */}
                    <div className='landing-hero'>
                        <div className='landing-hero-title'>
                            <h2>{"Transform Your iGaming Business with Digitain's"} <span>Retail, Affiliate</span> and <span>Agent Solution</span></h2>
                        </div>
                        <div className='landing-hero-content'>
                            <span>Unify In-Person and Online Experiences, Streamline Operations, and Manage Multiple Companies and Projects with Ease.</span>
                        </div>
                        <div className={'landing-hero-video' + (videoPaused ? " landing-hero-video-paused" : "" )} id='demoSection'>
                            <video className='rt--video' width="100%" controls loop>
                                <source src={Demo} type="video/mp4" />
                            </video>
                            <div className='rt--video-overlay'>
                                <div className='rt--video-overlay-btn'>
                                    <i className='ic_play' src={VideoPlay} onClick={playVideo}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img className='landing-borders-img' src={Borders}></img>
                </div>
                {/* HERO Section */}

                {/* Carousel Section */}

                <div className='landing-carousel'>
                    <div id="infinite" className="highway-slider">
                        <div className="container highway-barrier">
                            <ul className="highway-lane">
                                <li className="highway-system">
                                    <div className="slide">
                                        <img src={Project} alt="" />
                                        <span>Multi-project</span>
                                    </div>
                                </li>
                                <li className="highway-system">
                                    <div className="slide">
                                        <img src={MultiLingual} alt="" />
                                        <span>multilingual</span>
                                    </div>
                                </li>
                                <li className="highway-system">
                                    <div className="slide">
                                        <img src={Currency} alt="" />
                                        <span>Multi-currency</span>
                                    </div>
                                </li>
                                <li className="highway-system">
                                    <div className="slide">
                                        <img src={BetShops} alt="" />
                                        <span>unlimited betshops</span>
                                    </div>
                                </li>
                                <li className="highway-system">
                                    <div className="slide">
                                        <img src={Configurable} alt="" />
                                        <span>Multi-configurable Business models</span>
                                    </div>
                                </li>
                                <li className="highway-system">
                                    <div className="slide">
                                        <img src={Project} alt="" />
                                        <span>Multi-project</span>
                                    </div>
                                </li>
                                <li className="highway-system">
                                    <div className="slide">
                                        <img src={MultiLingual} alt="" />
                                        <span>multilingual</span>
                                    </div>
                                </li>
                                <li className="highway-system">
                                    <div className="slide">
                                        <img src={Currency} alt="" />
                                        <span>Multi-currency</span>
                                    </div>
                                </li>
                                <li className="highway-system">
                                    <div className="slide">
                                        <img src={BetShops} alt="" />
                                        <span>unlimited betshops</span>
                                    </div>
                                </li>
                                <li className="highway-system">
                                    <div className="slide">
                                        <img src={Configurable} alt="" />
                                        <span>Multi-configurable Business models</span>
                                    </div>
                                </li>
                                <li className="highway-system">
                                    <div className="slide">
                                        <img src={Project} alt="" />
                                        <span>Multi-project</span>
                                    </div>
                                </li>
                                <li className="highway-system">
                                    <div className="slide">
                                        <img src={MultiLingual} alt="" />
                                        <span>multilingual</span>
                                    </div>
                                </li>
                                <li className="highway-system">
                                    <div className="slide">
                                        <img src={Currency} alt="" />
                                        <span>Multi-currency</span>
                                    </div>
                                </li>
                                <li className="highway-system">
                                    <div className="slide">
                                        <img src={BetShops} alt="" />
                                        <span>unlimited betshops</span>
                                    </div>
                                </li>
                                <li className="highway-system">
                                    <div className="slide">
                                        <img src={Configurable} alt="" />
                                        <span>Multi-configurable Business models</span>
                                    </div>
                                </li>
                                <li className="highway-system">
                                    <div className="slide">
                                        <img src={BetShops} alt="" />
                                        <span>unlimited betshops</span>
                                    </div>
                                </li>
                                <li className="highway-system">
                                    <div className="slide">
                                        <img src={Configurable} alt="" />
                                        <span>Multi-configurable Business models</span>
                                    </div>
                                </li>
                                <li className="highway-system">
                                    <div className="slide">
                                        <img src={BetShops} alt="" />
                                        <span>unlimited betshops</span>
                                    </div>
                                </li>
                                <li className="highway-system">
                                    <div className="slide">
                                        <img src={Configurable} alt="" />
                                        <span>Multi-configurable Business models</span>
                                    </div>
                                </li>
                                <li className="highway-system">
                                    <div className="slide">
                                        <img src={BetShops} alt="" />
                                        <span>unlimited betshops</span>
                                    </div>
                                </li>
                                <li className="highway-system">
                                    <div className="slide">
                                        <img src={Configurable} alt="" />
                                        <span>Multi-configurable Business models</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* Carousel Section */}

                {/* SUBTITLE Section */}
                <div className='landing-sub-title' id='featuresSection'>
                    <h3>All-in-One Retail solution and Agent system</h3>
                    <div className='landing-sub-title-span'>
                        <span>Our system allows partners to easily access and utilize features together in one convenient location</span>
                    </div>
                </div>
                {/* SUBTITLE Section */}

                {/* SYSTEM BLOCKS */}
                <div className='landing-system'>
                    <div className='landing-system-cols'>
                        <div className='landing-system-inner'>
                            <div className='landing-system-inner-cont'>
                                <span className='landing-system-inner-num'>1</span>
                                <div className='landing-system-block'>
                                    <h4>Unlimited agent network</h4>
                                    <span>Each agent can have as many sub-agents as needed. Each agent can manage an unlimited number of players and bet shops</span>
                                </div>
                            </div>
                        </div>
                        <div className='landing-system-inner'>
                            <div className='landing-system-inner-cont'>
                                <span className='landing-system-inner-num'>2</span>
                                <div className='landing-system-block'>
                                    <h4>Flexible commission plans</h4>
                                    <span>Customize your commission plans based on source direction, income type, providers, source, calculation period, and more.</span>
                                </div>
                            </div>
                        </div>
                        <div className='landing-system-inner'>
                            <div className='landing-system-inner-cont'>
                                <span className='landing-system-inner-num'>3</span>
                                <div className='landing-system-block'>
                                    <h4>Comprehensive transactions</h4>
                                    <span>Our program includes a comprehensive transaction tool to ensure fast and flexible wallet management</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='landing-system-cols'>
                        <div className='landing-system-inner'>
                            <div className='landing-system-inner-cont'>
                                <span className='landing-system-inner-num'>4</span>
                                <div className='landing-system-block'>
                                    <h4>Wide range of permissions and roles</h4>
                                    <span>We offer a wide range of permissions and multiple types of roles to ensure secure and efficient management of your business</span>
                                </div>
                            </div>
                        </div>
                        <div className='landing-system-inner'>
                            <div className='landing-system-inner-cont'>
                                <span className='landing-system-inner-num'>5</span>
                                <div className='landing-system-block'>
                                    <h4>Betshops Management</h4>
                                    <span>Our program includes a bet shop management tool with custom cashier and frame solutions and multi provider support</span>
                                </div>
                            </div>
                        </div>
                        <div className='landing-system-inner'>
                            <div className='landing-system-inner-cont'>
                                <span className='landing-system-inner-num'>6</span>
                                <div className='landing-system-block'>
                                    <h4>Fast Targeted reports</h4>
                                    <span>Our system offers fast, targeted reports to help you track and analyze your business</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='landing-system-color'></div>
                </div>
                {/* SYSTEM BLOCKS */}

                {/* ... BLOCKS */}
                <div>
                    <div id='content' className='landing-admin'>
                        <div className="emblem">All-in-one*Retail - Agent,*and Affiliate*system</div>
                        <div className="emblem-rotate"></div>
                        <div className='flip-card'>
                            <div className="flip-card-inner">
                                <div className="flip-card-front">
                                    <img className='landing-admin-screen1' src={Admin4}></img>
                                </div>
                                <div className="flip-card-back">
                                    <span>Our system is easy to use, innovative, and meets all the criteria that operators need to streamline their operations and drive success. Our Retail & Agent system is flexible and offers a range of panels to help you manage your business more efficiently</span>
                                </div>
                            </div>
                        </div>
                        <div className='flip-card flip-card-two'>
                            <div className="flip-card-inner">
                                <div className="flip-card-front">
                                    <img className='landing-admin-screen2' src={Admin2}></img>
                                </div>
                                <div className="flip-card-back">
                                    <span>Overall, our Retail and Agent platform offers a comprehensive solution that can help you streamline your operations, manage your business more efficiently, and drive success</span>
                                </div>
                            </div>
                        </div>
                        <div className='flip-card flip-card-three'>
                            <div className="flip-card-inner">
                                <div className="flip-card-front">
                                    <img className='landing-admin-screen3' src={Admin3}></img>
                                </div>
                                <div className="flip-card-back">
                                    <span>The Companies panel allows you to manage multiple companies and projects, while the Admin Management panel lets you easily manage admins, permissions, and permission groups. The User Logs panel lets you keep track of user activity, and the CMS panel lets you customize bet coupons and manage translations. With our Settings panel, you can easily manage currencies and languages to ensure that your platform is accessible to a global audience. And, of course, our platform is secure, so you can trust that your data is protected</span>
                                </div>
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
                {/* ... BLOCKS */}

                {/* SUBTITLE Section */}

                <div className='landing-sub-title' id='advantagesSection'>
                    <h3>Key Advantages</h3>
                    <div className='landing-sub-title-span'>
                        <span>We provide the stability and trust by putting 3 things on the board</span>
                    </div>
                </div>

                {/* SUBTITLE Section */}

                {/* AFILATE Section */}

                <div className='landing-afilate'>
                    <div className='landing-afilate-cont'>
                        <div className='landing-afilate-block'>
                            <img src={Secure}></img>
                            <h4>Secure</h4>
                            <span>Operators have the ability to ensure that all incoming bets and sensitive information are protected from unauthorized access or breaches</span>
                        </div>
                        <div className='landing-afilate-block'>
                            <img src={Reliable}></img>
                            <h4>Reliable</h4>
                            <span>You can trust that the system will work as intended, without interruption or failure, allowing customers to focus on growing their business</span>
                        </div>
                        <div className='landing-afilate-block'>
                            <img src={Scalable}></img>
                            <h4>Scalable</h4>
                            <span>Easily adapt and grow your business without worrying about losing performance or efficiency</span>
                        </div>
                    </div>
                </div>

                {/* AFILATE Section */}

                {/* MULTI Section */}

                <div className='landing-multi'>
                    <div className='landing-multi-cont'>
                        <div className='landing-multi-block'>
                            <div className='landing-multi-block-title'>
                                <h3>Flexible and Multi-Configurable</h3>
                                <span>Expand your player base, customise permission levels and achieve full transparency in your operations.</span>
                            </div>
                            <div className='landing-multi-block-cont-all'>
                                <div className='landing-multi-block-cont-all-cont'>
                                    <div className='landing-multi-block-cont'>
                                        <h5>Multi company support</h5>
                                        <span>{"Users can create multiple companies and assign admin users to manage and operate each company's features"}</span>
                                    </div>
                                    <div className='landing-multi-block-cont-all-cont-hover'></div>
                                </div>

                                <div className='landing-multi-block-cont-all-cont'>
                                    <div className='landing-multi-block-cont'>
                                        <h5>Multi project support</h5>
                                        <span>Users can create multiple projects within each company, each with the option to include either retail, agent, or both</span>
                                    </div>
                                    <div className='landing-multi-block-cont-all-cont-hover'></div>
                                </div>


                                <div className='landing-multi-block-cont-all-cont'>
                                    <div className='landing-multi-block-cont'>
                                        <h5>Multiple languages and currencies</h5>
                                        <span>Our program supports multiple languages and currencies, making it easy to cater to a diverse audience</span>
                                    </div>
                                    <div className='landing-multi-block-cont-all-cont-hover'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img className='landing-borders-img landing-borders-img-square' src={BorderSquare}></img>
                </div>

                {/* MULTI Section */}

                {/* FOOTER Section */}
                <div className='landing-footer'>
                    <span>{new Date().getFullYear()} © All Rights Reserved <span className='landing-powered'>Powered by<img src={Digitain}></img> Digitain</span></span>
                </div>
                {/* FOOTER Section */}

            </div>
        </div>
    )
}

export default Landing;
